import { Link } from "react-router-dom";
import Logo from "../logo/logo";
import styles from "./header.module.scss"

/**
 * 
 * @param {String} actualPage to underline actual page
 * @returns 
 */
export default function Header({actualPage}) {
    return (
      <header>
       <Link to="/">
       <Logo backColor="none" fillColor="#FF6060"></Logo>
       </Link>
       
       <ul>
        <li><Link to="/" className={actualPage === "index" ? styles.underline : ""}>Accueil</Link></li>
        <li><Link to="/about" className={actualPage === "about" ? styles.underline : ""}>A propos</Link></li>
       </ul>
      </header>
    );
  }